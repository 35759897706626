import { useStepsContext } from "domain/public/components/StepsProvider";
import { useDriverIdentificationContext } from "domain/public/entities/driversIdentifications/Provider";
import { actionTypes } from "domain/public/entities/driversIdentifications/store/actions";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import useCountries from "hooks/useCountries";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPartyTypes } from "requests/clients";
import { PORTUGAL } from "utils/countries";
import Yup from "yup.js";
import { STEPS } from "../../../helper";

const useForm = () => {
  const { t } = useTranslation();
  const { entity } = usePublicEntityContext();
  const { dispatch, personalData } = useDriverIdentificationContext();
  const { goToStep } = useStepsContext();
  const { countryOptions, countryCodesMapping } = useCountries({ entityId: entity?.id });
  const [partyTypesOptions, setPartyTypesOptions] = useState([]);

  useEffect(() => {
    const fetchPartyTypes = async () => {
      const partyTypesResponse = await getPartyTypes(entity.id, { noLimit: true }, true);

      setPartyTypesOptions(
        partyTypesResponse.items.map((partyType) => ({
          label: partyType.designation,
          value: partyType.id,
        }))
      );
    };

    if (entity) {
      fetchPartyTypes();
    }
  }, [entity]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
    partyTypeId: Yup.number().required(t("10271") /* O campo é obrigatório */),
    taxpayerIdentificationNumber: Yup.string()
      .trim()
      .when("countryId", {
        is: PORTUGAL,
        then: Yup.string().required(t("10271") /* O campo é obrigatório */),
        otherwise: Yup.string(),
      }),
    economicActivityCode: Yup.number()
      .economicActivityCode(
        t("10306") /* Deve ser um valor numérico positivo com 5 caracteres */
      )
      .requiredIfCompany(t("10271") /* O campo é obrigatório */),
    commercialName: Yup.string()
      .trim()
      .requiredIfCompany(t("10271") /* O campo é obrigatório */),
    countryId: Yup.number().required(t("10271") /* O campo é obrigatório */),
    address: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
    zipCode: Yup.string()
      .trim()
      .required(t("10271") /* O campo é obrigatório */)
      .zipCode(
        t("9977") /* O campo é inválido para o país selecionado */,
        countryCodesMapping
      ),
    locality: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
    email: Yup.string()
      .trim()
      .required(t("10271") /* O campo é obrigatório */)
      .email(t("7469") /* O campo é inválido */),
    phone: Yup.string()
      .trim()
      .phone(t("7894") /* O campo deverá ser válido */, Yup.ref("countryId")),
    mobilePhone: Yup.string()
      .trim()
      .required(t("10271") /* O campo é obrigatório */)
      .phone(t("7894") /* O campo deverá ser válido */, Yup.ref("countryId")),
  });

  const submit = (values) => {
    dispatch({
      type: actionTypes.SET_PERSONAL_DATA,
      payload: values,
    });

    goToStep(STEPS.documents);
  };

  return {
    initialData: personalData,
    validationSchema,
    submit,
    countries: countryOptions,
    partyTypes: partyTypesOptions,
  };
};

export default useForm;
