import FlexBox from "components/FlexBox";
import SelectField from "components/formFields/Select";
import TextField from "components/formFields/Text";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { partyTypesWithCompanyFields } from "utils/clients";
import { PORTUGAL } from "utils/countries";
import FieldWrapper from "../../../../components/FieldWrapper";
import { formProps } from "./propTypes";
import useForm from "./useForm";

const Body = ({ values, readOnly }) => {
  const { t } = useTranslation();
  const { countries, partyTypes } = useForm();

  return (
    <div>
      <StyledFlexBox>
        <FieldWrapper>
          <SelectField
            className="form-field"
            name="partyTypeId"
            options={partyTypes}
            label={t("3659") /* Tipo */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper />
      </StyledFlexBox>
      <FieldWrapper>
        <TextField
          className="form-field"
          name="name"
          label={t("4518") /* Nome */}
          readOnly={readOnly}
        />
      </FieldWrapper>
      <StyledFlexBox>
        <FieldWrapper>
          <SelectField
            className="form-field"
            name="countryId"
            options={countries}
            label={t("2721") /* País */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="taxpayerIdentificationNumber"
            label={`${t("4198") /* NIF */} ${values.countryId === PORTUGAL ? "*" : ""}`}
            readOnly={readOnly}
          />
        </FieldWrapper>
      </StyledFlexBox>
      {partyTypesWithCompanyFields.includes(values.partyTypeId) && (
        <StyledFlexBox>
          <FieldWrapper>
            <TextField
              className="form-field"
              name="commercialName"
              label={`${t("9375") /* Designação comercial */} *`}
              readOnly={readOnly}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              className="form-field"
              name="economicActivityCode"
              label={`${t("6395") /* CAE */} *`}
              readOnly={readOnly}
              type="number"
            />
          </FieldWrapper>
        </StyledFlexBox>
      )}
      <FieldWrapper>
        <TextField
          className="form-field"
          name="address"
          label={t("2054") /* Rua */}
          readOnly={readOnly}
        />
      </FieldWrapper>
      <StyledFlexBox>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="complementaryAddress"
            label={t("8332") /* Bloco / Andar / Nº Porta */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper />
      </StyledFlexBox>
      <StyledFlexBox>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="zipCode"
            label={t("4830") /* Código postal */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="locality"
            label={t("4338") /* Localidade */}
            readOnly={readOnly}
          />
        </FieldWrapper>
      </StyledFlexBox>
      <FieldWrapper>
        <TextField
          className="form-field"
          name="email"
          label={t("178") /* Email */}
          readOnly={readOnly}
        />
      </FieldWrapper>
      <StyledFlexBox>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="phone"
            label={t("2050") /* Telefone */}
            readOnly={readOnly}
            type="tel"
            displayNativeControls={false}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="mobilePhone"
            label={t("398") /* Telemóvel */}
            readOnly={readOnly}
            type="tel"
            displayNativeControls={false}
          />
        </FieldWrapper>
      </StyledFlexBox>
    </div>
  );
};

Body.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  values: PropTypes.shape(formProps).isRequired,
};

export default Body;

const StyledFlexBox = styled(FlexBox)`
  column-gap: 30px;
  flex-flow: row wrap;
`;
